import { ReactElement } from 'react';

import { IComponentLinkListFields } from 'types/contentful';
import NavLink from 'components/UI/Nav/NavLink';

export default function LinkList({ heading, links }: IComponentLinkListFields): ReactElement {
  return (
    <div className="px-4 md:px-16 xl:px-32 py-12">
      <h2 className="font-heading text-2xl md:text-4xl">{heading}</h2>
      <div className="flex flex-wrap flex-col md:flex-row w-full md:w-5/6 mt-4">
        {links.map((link, i) => (
          <NavLink
            key={i + link.fields.text}
            classNames="w-full md:w-1/2 my-2 text-base md:text-xl underline"
            slug={link.fields.slug}
            text={link.fields.text}
            openInNewTab={link.fields.openInNewTab}
            currentSlug={'/'}
          />
        ))}
      </div>
    </div>
  );
}
